import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import {
  loadSettings,
  selectSettingsTalentUsageFee,
  selectSettingsLoading,
  selectW2TalentMarkdown,
} from 'src/store/settingsSlice';
import { SettingsParams } from 'src/types/settings';

type UseSettingsType = {
  talentUsageFee: number;
  w2TalentMarkdown: number | null;
  isLoading: boolean;
  getSettings: (params: SettingsParams) => { unwrap: () => void };
};

const useSettings = (): UseSettingsType => {
  const dispatch = useAppDispatch();
  const talentUsageFee = useAppSelector(state => selectSettingsTalentUsageFee(state));
  const w2TalentMarkdown = useAppSelector(state => selectW2TalentMarkdown(state));
  const isLoading = useAppSelector(state => selectSettingsLoading(state));
  const getSettings = (params: SettingsParams) => dispatch(loadSettings(params));

  return {
    talentUsageFee,
    w2TalentMarkdown,
    isLoading,
    getSettings,
  };
};

export default useSettings;
