import { isNil } from 'ramda';

import Availability from 'src/types/resources/Availability';
import { EmploymentType } from 'src/enums/Availability';
import { joinTruthy } from 'src/utils/string';
import { getShortMonthAndDate, getLongMonthAndDate } from 'src/utils/date';

const AvailabilityPresenter = {
  availableDate: (availability: Availability): string => {
    const { availableFromDate, availableToDate } = availability;

    return isNil(availableToDate)
      ? `From ${getLongMonthAndDate(availableFromDate)}`
      : `${getShortMonthAndDate(availableFromDate)} — ${getShortMonthAndDate(availableToDate)}`;
  },
  employmentTypes: (availability: Availability): string => {
    return Object.keys(EmploymentType)
      .filter(employmentType => availability[employmentType])
      .map(employmentType => EmploymentType[employmentType])
      .join(' / ');
  },
  description: (availability: Availability): string => {
    const { hoursPerWeek } = availability;

    const availableEmploymentTypes = AvailabilityPresenter.employmentTypes(availability);
    const availabilityDetails = [availableEmploymentTypes, `${hoursPerWeek} H/W`];
    return joinTruthy(availabilityDetails, ' • ');
  },
  organizationDescription: (availability: Availability): string => {
    const { hoursPerWeek } = availability;
    const availableEmploymentTypes = AvailabilityPresenter.employmentTypes(availability);

    return joinTruthy([availableEmploymentTypes, `${hoursPerWeek} H/W`], ' • ');
  },
  hoursPerWeek: (availability: Availability): string => {
    return `${availability.hoursPerWeek} H/W`;
  },
};

export default AvailabilityPresenter;
