import { EditorState } from 'draft-js';
import { isNil } from 'ramda';
import * as yup from 'yup';
import { parseISO, startOfTomorrow } from 'date-fns';

import Availability from 'src/types/resources/Availability';
import { MAX_HOURS_PER_WEEK, ValidationError } from 'src/enums/Validation';
import { getDateByYear } from 'src/utils/date';
import { convertFromHtmlFormat, convertToHtmlFormat, isEmptyTextEditor } from 'src/utils/textEditor';

import { GetMaxLengthTest, AVAILABILITY_WORK_LOCATION_CHECKED } from '../customRules';

export type AvailabilityFormData = {
  availableFromDate: Date;
  availableToDate?: Date;
  hoursPerWeek: number;
  responsibilities: {
    doOnSiteContract: boolean;
    doRemoteContract: boolean;
    doToHireContract: boolean;
  };
  note?: EditorState;
};

export type AvailabilityFormDataToSubmit = {
  availableFromDate: string;
  availableToDate?: string;
  hoursPerWeek: number;
  doOnSiteContract?: boolean;
  doRemoteContract?: boolean;
  doToHireContract?: boolean;
  note?: string;
};

const emptyEditorState = EditorState.createEmpty();

export const validationFields = {
  availableFromDate: yup
    .date()
    .nullable()
    .typeError(ValidationError.date)
    .min(startOfTomorrow(), ValidationError.dateStartTomorrow)
    .required(ValidationError.default)
    .default(null),
  availableToDate: yup
    .date()
    .nullable()
    .typeError(ValidationError.date)
    .min(yup.ref('availableFromDate'), ValidationError.dateEndBeforeDateStart)
    .default(null),
  hoursPerWeek: yup
    .number()
    .integer(ValidationError.integer)
    .min(0)
    .max(MAX_HOURS_PER_WEEK)
    .typeError(ValidationError.number)
    .required(ValidationError.default)
    .nullable()
    .default(null)
    .label('Hours per week'),
  responsibilities: yup
    .object()
    .shape({
      doOnSiteContract: yup.boolean().default(false),
      doRemoteContract: yup.boolean().default(false),
      doToHireContract: yup.boolean().default(false),
    })
    .test(AVAILABILITY_WORK_LOCATION_CHECKED),
  note: yup.mixed().test(GetMaxLengthTest(500)).default(emptyEditorState),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (availability: Availability | null = null): AvailabilityFormData => {
  if (isNil(availability)) {
    return validationSchema.getDefault();
  }

  const convertedNote = convertFromHtmlFormat(availability.note);

  return {
    availableFromDate: parseISO(availability.availableFromDate),
    availableToDate: availability.availableToDate && parseISO(availability.availableToDate),
    hoursPerWeek: Number(availability.hoursPerWeek),
    responsibilities: {
      doOnSiteContract: availability.doOnSiteContract,
      doRemoteContract: availability.doRemoteContract,
      doToHireContract: availability.doToHireContract,
    },
    note: convertedNote,
  };
};

export const attributesToSubmit = (values: AvailabilityFormData): AvailabilityFormDataToSubmit => {
  const { availableFromDate, availableToDate, note, responsibilities, ...restValues } = values;
  const convertedNote = convertToHtmlFormat(note);

  const result: AvailabilityFormDataToSubmit = {
    ...restValues,
    ...responsibilities,
    availableFromDate: getDateByYear(availableFromDate.toISOString()),
    availableToDate: availableToDate && getDateByYear(availableToDate.toISOString()),
    note: isEmptyTextEditor(note) ? null : convertedNote,
  };

  return result;
};
