import { COLORS } from 'src/theme/palette';
import { makeStyles } from 'src/utils/makeStyles';

const styles = makeStyles({
  timeTextField: {
    height: '20px',
    maxWidth: '24px',
    minWidth: 'auto',
    margin: 0,
  },
  timeTextFieldInput: {
    height: '20px',
    padding: 0,
    borderRadius: 0,
    boxShadow: 'none',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: 'transparent',
    '&.Mui-focused, &.Mui-error, &.Mui-focused.Mui-error': {
      boxShadow: 'none',
    },
    '&.Mui-error': {
      color: COLORS.red100,
    },
    '& input': {
      height: '20px',
      padding: 0,
      textAlign: 'right',
      '&::placeholder': {
        fontWeight: 400,
        color: COLORS.black100,
        opacity: 1,
      },
    },
  },
  timeDivider: {
    color: COLORS.black100,
    margin: '0 1px',
  },
  selectInput: {
    padding: '0 !important',
    height: '20px !important',
    minHeight: '20px !important',
  },
  selectMenu: {
    maxHeight: '170px',
    '& ul': {
      minWidth: 0,
    },
    '& li': {
      minHeight: 'auto',
      padding: '5px 15px',
      justifyContent: 'center',
    },
  },
  focused: {
    backgroundColor: COLORS.blue10,
  },
  readOnly: {
    pointerEvents: 'none',
  },
});

export default styles;
