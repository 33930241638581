import { useEffect } from 'react';

import OrganizationPresenter from 'src/presenters/OrganizationPresenter';
import UsersPresenter from 'src/presenters/UsersPresenter';
import { User } from 'src/types/resources/User';
import { initializePendoInstance, updatePendoInstance } from 'src/utils/pendo';

import useUsers from '../useUsers';

const launchPendo = (user: User, options: { isLoggedOutOfTrackedViaPendoUserBefore: boolean }) => {
  const isOrganizationTrackingViaPendo = OrganizationPresenter.isTrackedViaPendo(user.organization);
  const isUserTrackingViaPendo = UsersPresenter.isTrackedViaPendo(user);
  const metaData: pendo.InitOptions = {
    visitor: {
      id: user.id,
      first_name: user.firstName,
      last_name: user.lastName,
      username: user.username,
      email: user.email,
      role: user.role,
      data_joined: user.dateJoined,
      last_login: user.lastLogin,
      is_active: user.isActive,
      is_deactivated: user.isDeactivated,
      is_twilio_subscriber: user.isTwilioSubscriber,
      has_permission_send_engagement: user.hasPermissionSendEngagement,
    },
    ...(isOrganizationTrackingViaPendo && {
      account: {
        id: user.organization.id,
        name: user.organization.name,
        created_date: user.organization.createdDate,
        rate: user.organization.rate,
        city: user.organization.city,
        state: user.organization.state,
        is_active: user.organization.isActive,
        is_w2_type: user.organization.isW2Type,
        is_1099_type: user.organization.is1099Type,
      },
    }),
  };

  if (isUserTrackingViaPendo) {
    if (options.isLoggedOutOfTrackedViaPendoUserBefore) {
      updatePendoInstance(metaData);
    } else {
      initializePendoInstance(metaData);
    }
  }
};

export const usePendo = () => {
  const { currentUser, isLoggedOutOfTrackedViaPendoUserBefore } = useUsers();

  useEffect(() => {
    if (currentUser) {
      launchPendo(currentUser, {
        isLoggedOutOfTrackedViaPendoUserBefore,
      });
    }
  }, [currentUser?.id, isLoggedOutOfTrackedViaPendoUserBefore]);
};
