import { useState, ChangeEvent, useEffect } from 'react';
import { Typography, Button, Alert, List, ListItem, TextField, Stack, IconButton } from '@mui/material';
import { useFormik, getIn, FormikHelpers } from 'formik';
import { head, isNil, isEmpty } from 'ramda';

import { ReactComponent as CrossIcon } from 'src/assets/images/crossIcon.svg';
import { ProfileDocument } from 'src/enums/ProfileDocument';
import { useModals, useRouter } from 'src/hooks';
import appRoutes from 'src/routes/appRoutes';
import useDocuments from 'src/hooks/talents/useDocuments';
import clsx from 'src/utils/clsx';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import { REQUIRED_DOCUMENT_VALID_FILE_TYPES } from 'src/utils/constants';
import {
  attributesToSubmit,
  initialValues,
  DocumentsFormData,
  validationSchema,
  initialTouchedValues,
  DocumentFile,
} from 'src/forms/talents/documents';
import DatePicker from 'src/components/DatePicker';
import FileField from 'src/components/FileField';
import Box from 'src/components/Box';
import AttachedFileLink from 'src/components/AttachedFileLink';
import Loader from 'src/components/Loader';

import styles from './styles';

interface DocumentsProps {
  onSave?: () => void;
  onCancel?: () => void;
}

const Documents: React.FC<DocumentsProps> = props => {
  const [formError, setFormError] = useState<string | null>(null);
  const { documents, loadDocuments, updateDocuments, isLoadDocumentsFinished } = useDocuments();
  const { hideModal } = useModals();
  const { pathname } = useRouter();

  // Fetch documents onMount on the specializations page of the talent profile in the mobile view.
  useEffect(() => {
    if ((isNil(documents) || isEmpty(documents)) && pathname === appRoutes.talent.documents()) {
      loadDocuments();
    }
  }, []);

  const initialFormikValues = initialValues(documents);

  const onCancelClick = () => {
    const { onCancel } = props;
    hideModal();
    if (onCancel) {
      onCancel();
    }
  };

  const handleSubmit = async (
    formData: DocumentsFormData,
    prevFormData: DocumentsFormData,
    { setErrors }: FormikHelpers<DocumentsFormData>,
  ) => {
    const params = attributesToSubmit(formData, prevFormData);
    setFormError(null);
    try {
      await updateDocuments({ params }).unwrap();
      await loadDocuments().unwrap();
      hideModal();
      const { onSave } = props;
      if (onSave) {
        onSave();
      }
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        const nonFieldErrors = error?.response?.data?.errors?.nonFieldErrors;
        if (!isEmpty(nonFieldErrors)) {
          setFormError(head(nonFieldErrors));
        }
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    handleSubmit: handleFormikSubmit,
  } = useFormik<DocumentsFormData>({
    initialValues: initialFormikValues,
    initialTouched: initialTouchedValues,
    validationSchema,
    onSubmit: (currentFormikValues, formikHelpers) =>
      handleSubmit(currentFormikValues, initialFormikValues, formikHelpers),
    validateOnChange: true,
    enableReinitialize: true,
  });

  const handleFileChange = (fieldName: string) => async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (isNil(file)) {
      return;
    }
    const selectedFile = {
      source: file,
      name: file.name,
      url: null,
    } as DocumentFile;
    await setFieldTouched(`${fieldName}.file.source`, true);
    await setFieldValue(`${fieldName}.beginDate`, null);
    await setFieldValue(`${fieldName}.expirationDate`, null);
    await setFieldValue(`${fieldName}.file`, selectedFile);
  };

  const handleDeleteFile = (fieldName: string) => async () => {
    await setFieldValue(`${fieldName}.beginDate`, null);
    await setFieldValue(`${fieldName}.expirationDate`, null);
    await setFieldValue(`${fieldName}.uploadAt`, null);
    await setFieldValue(`${fieldName}.file`, null);
  };

  const renderFileField = (fieldName: string) => {
    const isFileExist = Boolean(getIn(values, `${fieldName}.file`));
    const buttonText = isFileExist ? 'Change file' : 'Select file';

    return (
      <FileField
        id={fieldName}
        accept={REQUIRED_DOCUMENT_VALID_FILE_TYPES.join(',')}
        onChange={handleFileChange(fieldName)}
        buttonText={buttonText}
      />
    );
  };

  const renderPreviewFileLink = (fieldName: string) => {
    const isFileFieldError = getIn(touched, `${fieldName}.file.source`) && getIn(errors, `${fieldName}.file.source`);
    const isFileExist = Boolean(getIn(values, `${fieldName}.file`));

    if (isFileFieldError) {
      return (
        <Typography sx={styles.documentHelperErrorText} component="p" color="error">
          {errors[fieldName].file.source}
        </Typography>
      );
    }

    return (
      isFileExist && (
        <Box sx={styles.documentPreviewLinkWrapper}>
          <AttachedFileLink sx={styles.documentPreviewLink} file={values[fieldName].file} />
          <IconButton
            sx={styles.deleteButton}
            onClick={handleDeleteFile(fieldName)}
            data-testid="talent-documents-modal-delete-btn"
          >
            <CrossIcon />
          </IconButton>
        </Box>
      )
    );
  };

  if (!isLoadDocumentsFinished) {
    return <Loader />;
  }

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h2" sx={styles.title}>
            Documents
          </Typography>
          <List sx={styles.list}>
            {Object.keys(values).map(fieldName => {
              const isFileSelected = getIn(values, `${fieldName}.file`);
              const isValidFile = isFileSelected && !getIn(errors, `${fieldName}.file.source`);

              const documentInfoStyles = clsx(styles.documentInfo, [[styles.documentInfoEmpty, !isFileSelected]]);

              return (
                <ListItem key={fieldName} sx={styles.listItem}>
                  <Box sx={styles.listItemContent}>
                    <Box sx={documentInfoStyles}>
                      <Typography variant="button" sx={styles.documentLabel}>
                        {ProfileDocument[fieldName]}
                      </Typography>
                      {renderPreviewFileLink(fieldName)}
                    </Box>

                    <Stack direction="row" spacing="16px" sx={styles.documentFields}>
                      {renderFileField(fieldName)}
                      {isValidFile && (
                        <>
                          <DatePicker
                            value={values[fieldName].beginDate}
                            onChange={date => {
                              setFieldValue(`${fieldName}.beginDate`, date);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                sx={styles.documentDate}
                                name={`${fieldName}.beginDate`}
                                inputProps={{ ...params.inputProps, placeholder: 'Begin Date' }}
                                error={Boolean(
                                  getIn(touched, `${fieldName}.beginDate`) && getIn(errors, `${fieldName}.beginDate`),
                                )}
                                helperText={
                                  getIn(touched, `${fieldName}.beginDate`) && getIn(errors, `${fieldName}.beginDate`)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          />
                          <DatePicker
                            minDate={new Date(values[fieldName].beginDate)}
                            value={values[fieldName].expirationDate}
                            onChange={date => {
                              setFieldValue(`${fieldName}.expirationDate`, date, true);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                sx={[styles.documentDate, { mr: '1px' }]}
                                name={`${fieldName}.expirationDate`}
                                inputProps={{ ...params.inputProps, placeholder: 'Expiration Date' }}
                                error={Boolean(
                                  getIn(touched, `${fieldName}.expirationDate`) &&
                                    getIn(errors, `${fieldName}.expirationDate`),
                                )}
                                helperText={
                                  getIn(touched, `${fieldName}.expirationDate`) &&
                                  getIn(errors, `${fieldName}.expirationDate`)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          />
                        </>
                      )}
                    </Stack>
                  </Box>
                </ListItem>
              );
            })}
          </List>

          <Typography sx={styles.documentFormats} variant="body1" color="black40">
            *Files formats supported include {REQUIRED_DOCUMENT_VALID_FILE_TYPES.join(', ')}.
          </Typography>

          <Box>
            <Button
              type="submit"
              variant="contained"
              sx={styles.button}
              disabled={isSubmitting}
              data-testid="talent-documents-modal-save-btn"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onCancelClick}
              sx={styles.button}
              data-testid="talent-documents-modal-cancel-btn"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default Documents;
