import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { useModals } from 'src/hooks';
import Box from 'src/components/Box';
import TextEditor from 'src/components/TextEditor';
import {
  attributesToSubmit,
  initialValues,
  OpportunityApplyFormData,
  validationSchema,
} from 'src/forms/talents/opportunityApply';
import { MAX_APPLY_MESSAGE_TEXTAREA_LENGTH } from 'src/enums/Validation';
import { TalentOpportunityApplyInfo } from 'src/features/opportunities/types/talentOpportunityApply';

import styles from './styles';

type Props = {
  handleApply: (data: TalentOpportunityApplyInfo) => void;
};
const TalentOpportunityApplyForm: React.FC<Props> = ({ handleApply }) => {
  const { hideModal } = useModals();

  const handleSubmit = (formData: OpportunityApplyFormData) => {
    const paramsToSubmit = attributesToSubmit(formData);
    handleApply(paramsToSubmit);
  };
  const { values, errors, submitForm, touched, setFieldValue } = useFormik<OpportunityApplyFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h3">Confirm That You Are the Ideal Talent</Typography>
      <Typography>
        Note to applicants: Before applying, review the opportunity details and ensure you meet the qualifications. Your
        responses serve as a crucial prescreen, so highlight relevant skills and experience that align with the
        opportunity. The Opportunity Manager can view your profile—avoid copying existing information and providing the
        same answer for each section.
      </Typography>
      <TextEditor
        labelSx={{ whiteSpace: 'normal' }}
        label="What relevant skills and qualifications do you possess that make you a strong candidate for this Opportunity?"
        isCounterVisible
        maxValue={MAX_APPLY_MESSAGE_TEXTAREA_LENGTH}
        placeholder="Highlight relevant qualifications and skills that directly align with this Opportunity."
        value={values.skillsAndQualifications}
        onChange={newState => setFieldValue('skillsAndQualifications', newState)}
        error={touched.skillsAndQualifications && errors.skillsAndQualifications}
        usePredefinedMinHeight
      />
      <TextEditor
        label="What relevant experience do you have?"
        isCounterVisible
        maxValue={MAX_APPLY_MESSAGE_TEXTAREA_LENGTH}
        placeholder="Highlight previous job experience that is relevant to this Opportunity."
        value={values.experience}
        onChange={newState => setFieldValue('experience', newState)}
        error={touched.experience && errors.experience}
        usePredefinedMinHeight
      />
      <TextEditor
        label="Can you share more details about yourself?"
        isCounterVisible
        maxValue={MAX_APPLY_MESSAGE_TEXTAREA_LENGTH}
        placeholder="Share with us the reasons that make you a strong candidate for this Opportunity."
        value={values.applyMessage}
        onChange={newState => setFieldValue('applyMessage', newState)}
        error={touched.applyMessage && errors.applyMessage}
        usePredefinedMinHeight
      />
      <Box sx={styles.buttons}>
        <Button variant="contained" sx={styles.button} onClick={submitForm}>
          Apply
        </Button>
        <Button variant="outlined" sx={styles.button} onClick={hideModal}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default TalentOpportunityApplyForm;
