import React, { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Typography, Button, TextField, Alert } from '@mui/material';
import { isNil, head } from 'ramda';

import useCertificates from 'src/hooks/talents/useCertificates';
import useModals from 'src/hooks/useModals';
import {
  validationSchema,
  CertificateFormData,
  initialValues,
  attributesToSubmit,
} from 'src/forms/talents/certificate';
import CertificateType from 'src/types/resources/CertificateType';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import UploadFile from 'src/components/UploadFile';
import Box from 'src/components/Box';

import styles from './styles';

type FormProps = {
  certificate?: CertificateType;
};

const Form: React.FC<FormProps> = props => {
  const { certificate } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { loadCertificates, createCertificate, updateCertificate } = useCertificates();

  const isAddForm = isNil(certificate);
  const formTitle = `${isAddForm ? 'Add' : 'Edit'} Resume, Certifications and More`;

  const handleSubmit = async (
    values: CertificateFormData,
    { setErrors, setSubmitting }: FormikHelpers<CertificateFormData>,
  ) => {
    const params = attributesToSubmit(values);
    setFormError(null);
    try {
      if (isAddForm) {
        await createCertificate(params).unwrap();
      } else {
        await updateCertificate({ id: certificate.id, params }).unwrap();
      }
      loadCertificates();
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        const nonFieldErrors = error?.response?.data?.errors?.nonFieldErrors;
        if (nonFieldErrors) {
          setFormError(head(nonFieldErrors));
        }
        setErrors(parseToFormikErrors(errors));
      } else {
        setFormError('An unexpected error occurred');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = useFormik<CertificateFormData>({
    initialValues: initialValues(certificate),
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleFileUpload = (file: File | null) => {
    setFieldTouched('file');
    setFieldValue('file', {
      source: file,
      url: null,
    });
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.box}>
          <Typography variant="h3" align="center" sx={styles.heading}>
            {formTitle}
          </Typography>
          <Box sx={styles.row}>
            <TextField
              id="documentName"
              label="Document Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.documentName}
              error={touched.documentName && Boolean(errors.documentName)}
              helperText={touched.documentName && errors.documentName}
            />
          </Box>
          <Box sx={styles.row}>
            <TextField
              id="schoolOrUniversity"
              label="School/University"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.schoolOrUniversity}
              error={touched.schoolOrUniversity && Boolean(errors.schoolOrUniversity)}
              helperText={touched.schoolOrUniversity && errors.schoolOrUniversity}
            />
          </Box>
          <Box sx={styles.row}>
            <TextField
              id="fieldOfStudy"
              label="Field of study"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.fieldOfStudy}
              error={touched.fieldOfStudy && Boolean(errors.fieldOfStudy)}
              helperText={touched.fieldOfStudy && errors.fieldOfStudy}
            />
          </Box>
          <Box sx={styles.row}>
            <UploadFile
              error={touched.file && Boolean(errors.file)}
              helperText={touched.file && (errors.file?.source || errors.file)}
              fileUrl={certificate?.file}
              onChange={handleFileUpload}
            />
          </Box>
          <Box sx={styles.footer}>
            <Button
              type="submit"
              variant="contained"
              sx={styles.button}
              disabled={isSubmitting}
              data-testid="talent-certificate-form-save-btn"
            >
              {isAddForm ? 'Add' : 'Save'}
            </Button>
            <Button
              variant="outlined"
              sx={styles.button}
              onClick={hideModal}
              data-testid="talent-certificate-form-cancel-btn"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default Form;
