/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'ramda';
import { TestContext, ValidationError as YupValidationError } from 'yup';
import { EditorState } from 'draft-js';

import { ValidationError, ValidFileSize, FileType } from 'src/enums/Validation';
import { PasswordStrength } from 'src/enums/Password';
import { MIN_PASSWORD_LENGTH } from 'src/utils/constants';
import { bytesToMegabytes } from 'src/utils/filesize';
import { isString, stringHasWhitespace } from 'src/utils/string';
import { isValidDatePeriod, isValidSingleYear } from 'src/utils/date';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { getPasswordStrength, getPasswordRequirementMessage } from 'src/utils/password';
import { isEmptyTextEditor } from 'src/utils/textEditor';

const maxFileTypeSize = {
  talentVideoIntroduction: 200 * 1024 * 1024, // 200MB
  poDocument: 5 * 1024 * 1024, // 5MB
};

type TestOptions = {
  name: string;
  test: (value: any) => boolean;
  message: string;
};

export const NO_SPACES_RULE = {
  name: 'no-spaces',
  test: (value: string): boolean => (value ? !stringHasWhitespace(value) : true),
  message: 'No spaces',
};

export const VALID_DATE_PERIOD = {
  name: 'valid-period',
  test: (value: string): boolean => (value ? isValidDatePeriod(value) : false),
  message: 'Invalid date period',
};

export const VALID_SINGLE_YEAR = {
  name: 'valid-single-year',
  test: (value: string): boolean => (value ? isValidSingleYear(value) : false),
  message: 'Invalid date',
};

export const getValidFileTypeTest = (validFileTypes: FileType[]): TestOptions => {
  return {
    name: 'valid-file-type',
    test: (value: File | null): boolean => {
      if (isNil(value) || isString(value)) {
        return true;
      }
      return validFileTypes.some(
        validFileType => value.name?.endsWith(validFileType) || value.name?.endsWith(validFileType.toUpperCase()),
      );
    },
    message: 'Unsupported file format',
  };
};

export const VALID_FILE_SIZE = {
  name: 'valid-filesize',
  test: (value: File, context: TestContext): boolean | YupValidationError => {
    const { path } = context;

    const maxFileSize = ValidFileSize[path] || 0;

    if (isNil(value) || isString(value) || value?.size <= maxFileSize) {
      return true;
    }

    return context.createError({
      path,
      message: `File size exceeds the max. limit of ${bytesToMegabytes(maxFileSize)}`,
    });
  },
};

export const createFileExtensionValidation = (validFileTypes: FileType[], name = 'valid-file-type') => {
  return {
    name,
    test: (value: File | null): boolean => {
      if (isNil(value) || isString(value)) {
        return true;
      }
      return validFileTypes.some(
        validFileType => value.name?.endsWith(validFileType) || value.name?.endsWith(validFileType.toUpperCase()),
      );
    },
    message: 'File format is not supported.',
  };
};

export const createMaxFileSizeValidation = (fileKey: keyof typeof maxFileTypeSize, name = 'valid-max-file-size') => {
  const maxFileSize = maxFileTypeSize[fileKey] || 0;
  return {
    name,
    test: (value: File, context: TestContext): boolean | YupValidationError => {
      const { path } = context;

      if (isNil(value) || isString(value) || value?.size <= maxFileSize) {
        return true;
      }

      return context.createError({
        path,
        message: `File size exceeds the maximum limit of ${bytesToMegabytes(maxFileSize)}.`,
      });
    },
  };
};

export const ONE_OF_CHECKBOXES_TRUE = {
  name: 'one-of-checkboxes-true',
  test: (object: Record<string, boolean>): boolean => Object.values(object).some(Boolean),
  message: 'Check one or all that apply',
};

export const AVAILABILITY_WORK_LOCATION_CHECKED = {
  name: 'availability-work-location-checked',
  test: (workLocation: Record<string, boolean>): boolean =>
    workLocation.doOnSiteContract || workLocation.doRemoteContract,
  message: 'Select at least one work location',
};

export const WYSIWYG_NOT_EMPTY = {
  name: 'wysiwyg-not-empty',
  test: (editorState: EditorState): boolean => !isEmptyTextEditor(editorState),
  message: ValidationError.default,
};

export const GetMaxLengthTest = (length = 500): TestOptions => {
  return {
    name: 'wysiwyg-max-length',
    test: (editorState: EditorState): boolean => {
      return editorState.getCurrentContent().getPlainText('').length < length;
    },
    message: getMaxFieldLengthErrorMessage(length),
  };
};

export const STRONG_PASSWORD = {
  name: 'strong-password',
  test: (value: string, context: TestContext): boolean | YupValidationError => {
    const { path, createError } = context;
    const { contains, length, value: passwordStrengthValue } = getPasswordStrength(value);
    const isPasswordStrong = passwordStrengthValue === PasswordStrength.strong;
    const isPasswordLengthValid = length >= MIN_PASSWORD_LENGTH;

    if (isPasswordStrong) return true;

    return createError({ path, message: getPasswordRequirementMessage(contains, isPasswordLengthValid) });
  },
};

export const VALID_PHONE_NUMBER = {
  name: 'valid-phone-number',
  test: (value: string): boolean => !value.includes('_'),
  message: ValidationError.phone,
};
