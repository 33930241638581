import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ReactGA from 'react-ga4';
import { Suspense, useEffect, useState } from 'react';

import useUsers from 'src/hooks/useUsers';
import useSettings from 'src/hooks/useSettings';
import Router from 'src/components/Router';
import Loader from 'src/components/Loader';
import { getGoogleAnalyticsMeasurementID } from 'src/config/googleAnalytics';
import { usePendo } from 'src/hooks/usePendo';

const App: React.FC = () => {
  const [isInitialLoadFinished, setIsInitialLoadFinished] = useState<boolean>(false);
  const { currentUser, loadCurrentUser } = useUsers();
  const { getSettings } = useSettings();
  usePendo();

  const initLogRocketIdentify = async () => {
    try {
      const userId: string = process.env.REACT_APP_ENV + currentUser.id;

      LogRocket.identify(userId.toString(), {
        userId,
        name: currentUser.fullName,
        email: currentUser.email,
        role: currentUser.role,
        env: process.env.REACT_APP_ENV,
      });
    } catch (error) {
      console.error('Error identify LogRocket', error);
    }
  };

  useEffect(() => {
    try {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_TOKEN, {
        dom: { inputSanitizer: true },
      });
      setupLogRocketReact(LogRocket);
    } catch (error) {
      console.error('Error init LogRocket', error);
    }
    if (currentUser) {
      initLogRocketIdentify();
    }
  }, [currentUser?.id]);

  useEffect(() => {
    try {
      const googleAnalyticsMeasurementID = getGoogleAnalyticsMeasurementID();
      ReactGA.initialize(googleAnalyticsMeasurementID);
    } catch (error) {
      console.error('Error init Google Analytics', error);
    }
  }, []);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        await loadCurrentUser().unwrap();
      } catch (e) {
        console.error('Unauthenticated user');
      } finally {
        setIsInitialLoadFinished(true);
      }
      getSettings({ name: 'talent_usage_fee' });
    };

    initialLoad();
  }, []);

  if (!isInitialLoadFinished) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      <Router />
    </Suspense>
  );
};

export default App;
