import { MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import { isEmpty } from 'ramda';

type MinutesFieldProps = TextFieldProps;

const MENU_ITEMS = [
  { label: '00', value: 0 },
  { label: '15', value: 0.25 },
  { label: '30', value: 0.5 },
  { label: '45', value: 0.75 },
];

const MinutesField: React.FC<MinutesFieldProps> = props => {
  const { SelectProps, ...restProps } = props;

  const renderValue = (value: unknown) => {
    if (!isEmpty(value)) {
      return MENU_ITEMS.find(menuItem => menuItem.value === value)?.label;
    }

    return (
      <Typography variant="body1" color="black100">
        mm
      </Typography>
    );
  };

  return (
    <TextField
      select
      SelectProps={{
        IconComponent: () => null,
        displayEmpty: true,
        renderValue,
        ...SelectProps,
      }}
      {...restProps}
    >
      {MENU_ITEMS.map(item => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default MinutesField;
