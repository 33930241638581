import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import {
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Alert,
  FormHelperText,
} from '@mui/material';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import { isNil, head } from 'ramda';
import { EditorState } from 'draft-js';

import {
  AvailabilityFormData,
  initialValues,
  attributesToSubmit,
  validationSchema,
} from 'src/forms/talents/availability';
import Availability from 'src/types/resources/Availability';
import clsx from 'src/utils/clsx';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useAvailabilities from 'src/hooks/talents/useAvailabilities';
import useModals from 'src/hooks/useModals';
import useScreen from 'src/hooks/useScreen';
import DatePicker from 'src/components/DatePicker';
import TextEditor from 'src/components/TextEditor';
import Box from 'src/components/Box';
import { useSnackbar } from 'src/hooks';

import styles from './styles';

type TalentAvailabilityFormProps = {
  availability?: Availability;
  onAfterSubmit?: () => void;
  onCancel?: () => void;
};

const TalentAvailabilityForm: React.FC<TalentAvailabilityFormProps> = props => {
  const { availability, onAfterSubmit = null, onCancel = null } = props;
  const isAddForm = isNil(availability);
  const formTitle = isAddForm ? 'New Availability' : 'Edit Availability';
  const { enqueueErrorSnackbar } = useSnackbar();
  const { hideModal } = useModals();
  const [formError, setFormError] = useState<string>(null);
  const { loadAvailabilities, createAvailability, updateAvailability } = useAvailabilities();
  const { lessThanTablet } = useScreen();

  const handleSubmit = async (
    formData: AvailabilityFormData,
    { setSubmitting, setErrors }: FormikHelpers<AvailabilityFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      if (isAddForm) {
        await createAvailability(params).unwrap();
      } else {
        await updateAvailability({ id: availability.id, params }).unwrap();
      }
      await loadAvailabilities();
      if (!isNil(onAfterSubmit)) {
        onAfterSubmit();
      } else {
        hideModal();
      }
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(head(error.response.data.errors.nonFieldErrors));
        setErrors(parseToFormikErrors(errors));
      } else {
        enqueueErrorSnackbar();
      }
    } finally {
      setSubmitting(false);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit: handleFormikSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormik<AvailabilityFormData>({
    initialValues: initialValues(availability),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const groupRowStyles = clsx(styles.row, [[styles.column, lessThanTablet]]);
  const checkboxRowStyles = clsx(styles.row, [[styles.checkboxRow, true]]);

  const handleCheckboxChange = (fieldValuePath: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldTouched('responsibilities');
    setFieldValue(`responsibilities.${fieldValuePath}`, checked);
  };

  const handleWisywigChange = (editorState: EditorState) => {
    setFieldValue('note', editorState);
  };

  const handleCancelButtonClick = () => {
    if (!isNil(onCancel)) {
      onCancel();
    } else {
      hideModal();
    }
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.box}>
          <Typography variant={lessThanTablet ? 'h4' : 'h2'} align="center" sx={styles.heading} component="h2">
            {formTitle}
          </Typography>
          <Box sx={styles.dateRow}>
            <DatePicker
              label="Available Date"
              minDate={startOfTomorrow()}
              value={values.availableFromDate}
              onChange={date => {
                setFieldValue('availableFromDate', date);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  name="availableFromDate"
                  inputProps={{ ...params.inputProps, placeholder: 'From' }}
                  error={touched.availableFromDate && Boolean(errors.availableFromDate)}
                  helperText={touched.availableFromDate && errors.availableFromDate}
                  onBlur={handleBlur}
                />
              )}
            />
            <DatePicker
              minDate={new Date(values.availableFromDate) || startOfTomorrow()}
              value={values.availableToDate}
              onChange={date => {
                setFieldValue('availableToDate', date, true);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  name="availableToDate"
                  className="availableToDateField"
                  inputProps={{ ...params.inputProps, placeholder: 'To (optional)' }}
                  error={touched.availableToDate && Boolean(errors.availableToDate)}
                  helperText={touched.availableToDate && errors.availableToDate}
                  onBlur={handleBlur}
                />
              )}
            />
          </Box>
          <Box sx={groupRowStyles}>
            <TextField
              id="hoursPerWeek"
              label="Hours per week"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.hoursPerWeek}
              error={touched.hoursPerWeek && Boolean(errors.hoursPerWeek)}
              helperText={touched.hoursPerWeek && errors.hoursPerWeek}
            />
            {/* This element is used to display the hoursPerWeek field on half a line. To align the element with the top
            fields */}
            <Box sx={{ width: '100%' }} />
          </Box>
          <Box sx={checkboxRowStyles}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend" error={touched.responsibilities && Boolean(errors.responsibilities)}>
                Assign responsibility
              </FormLabel>
              <FormGroup sx={styles.checkboxGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="doOnSiteContract"
                      onChange={handleCheckboxChange('doOnSiteContract')}
                      checked={Boolean(values.responsibilities.doOnSiteContract)}
                    />
                  }
                  label="On Site Contract"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="doRemoteContract"
                      onChange={handleCheckboxChange('doRemoteContract')}
                      checked={Boolean(values.responsibilities.doRemoteContract)}
                    />
                  }
                  label="Remote Contract"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="doToHireContract"
                      onChange={handleCheckboxChange('doToHireContract')}
                      checked={Boolean(values.responsibilities.doToHireContract)}
                    />
                  }
                  label="I’m interested in Contract to Hire"
                />
              </FormGroup>
            </FormControl>
            {touched.responsibilities && Boolean(errors.responsibilities) && (
              <FormHelperText error>{errors.responsibilities}</FormHelperText>
            )}
          </Box>
          <Box sx={styles.row}>
            <TextEditor
              label="Notes"
              value={values.note}
              onChange={handleWisywigChange}
              error={touched.note && errors.note}
            />
          </Box>
          <Box sx={styles.buttons}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              {isAddForm ? 'Add' : 'Save'}
            </Button>
            <Button variant="outlined" sx={styles.button} onClick={handleCancelButtonClick}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TalentAvailabilityForm;
