/* eslint-disable no-console */
export function initializePendoInstance(options: pendo.InitOptions) {
  window.pendo.initialize(options);
  if (window.pendo?.isReady?.()) {
    console.info('Pendo Agent initialized. Events will be written.');
  } else {
    console.error('Pendo Agent was not initialized. Events will not be written.');
  }
}

export function updatePendoInstance(options: pendo.InitOptions) {
  const pendoMessage = window.pendo?.startSendingEvents?.();
  window.pendo.updateOptions(options);

  if (window.pendo?.isReady?.()) {
    console.info(`${pendoMessage.slice(0, 20)} and updated with a new user${pendoMessage.slice(20)}`);
  } else {
    console.warn('Pendo Agent was not initialized. Events will not be written.');
  }
}

export function clearPendoInstance() {
  const pendoMessage = window.pendo?.stopSendingEvents?.();
  window.pendo?.clearSession?.();
  if (pendoMessage) {
    console.info('Pendo Agent stopped sending events and cleared session.');
  } else {
    console.warn('Pendo Agent was not initialized, no session to clear.');
  }
}
