/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
import axios, { AxiosResponse, AxiosError } from 'axios';
import qs from 'qs';
import { isEmpty, set, lensPath, path } from 'ramda';
import { serialize } from 'object-to-formdata';
import Cookies from 'js-cookie';

import { AxiosHeaders } from 'src/types/utils';
import appRoutes from 'src/routes/appRoutes';
import { camelizeKeys, decamelizeKeys } from 'src/utils/keysConverter';
import history from 'src/utils/history';
import { isCurrentLocationOneOf } from 'src/utils/location';
import { isUnauthorizedError } from 'src/utils/responseErrors';

export const DEBOUNCE_WAIT = 300;

export const axiosInstance = axios.create({
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

axiosInstance.interceptors.request.use(config => {
  config.headers.common['X-CSRFToken'] = Cookies.get('csrftoken');
  return config;
});

axiosInstance.interceptors.response.use(null, error => {
  if (isUnauthorizedError(error)) {
    // isCurrentLocationOneOf accept array of locations
    const shouldRedirectToRoot = isCurrentLocationOneOf([]);

    if (shouldRedirectToRoot) {
      history.push(appRoutes.rootPath());
    }
  }
  throw error;
});

// Axios middleware to convert all api responses to camelCase
// including RTK Query
axiosInstance.interceptors.response.use(response => {
  if (response.data && response.headers['content-type'] === 'application/json') {
    response.data = isEmpty(response.data) ? {} : camelizeKeys(response.data);
  }

  return response;
});

export const handleResponse = (response: AxiosResponse) => {
  return response.data;
};

const handleResponseWithHeaders = ({ data, headers }: AxiosResponse) => {
  return { headers, data: isEmpty(data) ? {} : camelizeKeys(data) };
};

const handleError = (axiosError: AxiosError) => {
  const errorsPath = ['response', 'data', 'errors'];
  const axiosErrorResponseWithCamelizedErrors = set(
    lensPath(errorsPath),
    camelizeKeys(path(errorsPath, axiosError)),
    axiosError,
  );

  throw axiosErrorResponseWithCamelizedErrors;
};

const toFormData = <D>(data: D) => {
  const decamelizedParams = decamelizeKeys(data);
  return serialize(decamelizedParams, { indices: true, allowEmptyArrays: true });
};

const Fetcher = {
  get: <R>(url: string, params?: QueryParams): Promise<R> =>
    axiosInstance
      .get(url, { params: params ? decamelizeKeys(params) : null })
      .then(handleResponse)
      .catch(handleError),
  post: <D, R>(url: string, data?: D): Promise<R> => {
    return axiosInstance.post(url, decamelizeKeys(data)).then(handleResponse).catch(handleError);
  },
  put: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance.put(url, decamelizeKeys(data)).then(handleResponse).catch(handleError),
  patch: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance.patch(url, decamelizeKeys(data)).then(handleResponse).catch(handleError),
  delete: (url: string): Promise<null> => axiosInstance.delete(url).then(handleResponse),
  postFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axiosInstance
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      })
      .then(handleResponse)
      .catch(handleError);
  },
  putFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axiosInstance
      .put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      })
      .then(handleResponse)
      .catch(handleError);
  },
  updateFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axiosInstance
      .put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      })
      .then(handleResponse)
      .catch(handleError);
  },
  partialUpdateFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axiosInstance
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      })
      .then(handleResponse)
      .catch(handleError);
  },
  getWithHeader: <R>(url: string, params?: QueryParams): Promise<{ headers: AxiosHeaders; data: R }> =>
    axiosInstance
      .get(url, { params: params ? decamelizeKeys(params) : null })
      .then(handleResponseWithHeaders)
      .catch(handleError),
};

export default Fetcher;
